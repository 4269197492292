import { useQuery } from "react-query";
import { fetchCalendarEntry } from "@/features/Calendar/services";
import { serializeCalendarData } from "@/features/Calendar/serializer";
import { CalendarRange } from "@/features/Calendar/components/CalendarRangeCells/constants";

type Props = {
  from: string;
  to: string;
};

export const useCalendarEntryQuery = (props: Props) => {
  return useQuery("calendarEntry", {
    queryFn: async () => {
      const { items } = await fetchCalendarEntry(props);
      return items;
    },
  });
};
