import { map } from "lodash";
import { useMemo } from "react";
import { getRangeValues } from "./constants";
import TableCell from "@/components/UI/Table/TableCell";
import TableRow from "@/components/UI/Table/TableRow";
import type { CalendarRangeProps } from "./types";
import styles from "./styles.module.scss";

export const CalendarRangeCells = ({ range }: CalendarRangeProps) => {
  const ranges = useMemo(() => getRangeValues(range), [range]);

  const renderCells = useMemo(() => {
    return map(ranges.values, (value, index) => (
      <TableCell key={index}>{value.format("HH:mm")}</TableCell>
    ));
  }, [range]);

  return (
    <TableRow className={styles.row}>
      <TableCell>{ranges.label}</TableCell>
      {renderCells}
    </TableRow>
  );
};
