import { useEffect, useState } from "react";
import Meta from "@/components/Meta";
import Grid from "@/components/UI/Grid";
import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import IconButton from "@/components/UI/IconButton";
import IconPrint from "@/components/Icons/IconPrint";
import OrderStatuses from "../OrderStatuses";
import { useParams } from "react-router";
import Tabs from "@/components/UI/Tabs";
import { OrderTab, orderTabs } from "@/features/Order/constants";
import { useAppProvider } from "@/providers/AppProvider/hooks";
import OrderJobs from "@/features/Order/components/OrderJobs";
import { getOrderByID } from "@/features/Order/services";
import {
  OrderEntity,
  OrderNoteEntity,
  OrderStatusEnum,
} from "@/features/Order/types";
import OrderProducts from "@/features/Order/components/OrderProducts";
import { useEventListener } from "@/hooks";
import { EVENTS } from "@/constants";
import { If } from "@/components/ConditionalRendering/If";
import { getAgentFullName } from "@/pages/AgentDetail/utils";
import { Agent, UserType } from "@/types";
import { AgentType } from "@/features/Agent/constants";
import { searchLegalAgents } from "@/features/Agent/service";
import LoadingIndicatorPage from "@/components/LoadingIndicatorPage";
import {
  CarEntity,
  CarModelEntity,
} from "@/features/Car/components/Cars/types";
import { Scrollbar } from "react-scrollbars-custom";
import OrderNotes from "@/features/Order/components/OrderNotes";
import OrderPaymentFormModal from "@/features/Order/components/OrderPaymentFormModal";
import { OrderCalendarForm } from "@/features/Order/components/OrderForm/OrderCalendarForm";
import { OrderTitle } from "@/features/Order/components/OrderForm/OrderTitle";
import { OrderIndividualAgentForm } from "../OrderForm/OrderIndividualAgentForm";
import { OrderLegalAgentForm } from "../OrderForm/OrderLegalAgentForm";
import { OrderCarForm } from "../OrderForm/OrderCarForm";
import styles from "./styles.module.scss";

const OrderForm = () => {
  const { id } = useParams();
  const [agentType, setAgentType] = useState<AgentType>(AgentType.Individual);
  const [isFetching, setIsFetching] = useState(true);
  const [showPaymentFormModal, setShowPaymentFormModal] = useState(false);
  const { currentSubdivision } = useAppProvider();
  const [brand, setBrand] = useState<Partial<CarEntity> | null>();
  const [model, setModel] = useState<Partial<CarModelEntity> | null>(null);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [cars, setCars] = useState<CarEntity[]>([]);
  const [carModels, setCarModels] = useState<CarModelEntity[]>([]);
  const [order, setOrder] = useState<OrderEntity>();
  const [orderTab, setOrderTab] = useState<OrderTab>(OrderTab.Job);

  const refreshData = () => {
    setIsFetching(true);
    fetchData(id as string);
  };

  const fetchData = (id: string) => {
    getOrderByID(id)
      .then((data) => {
        setOrder(data);

        if (data?.car?.sourceCar?.brand && data?.car?.sourceCar?.model) {
          setBrand({ name: data?.car?.sourceCar?.brand });
          setModel({ name: data?.car?.sourceCar?.model });
        }
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    setIsFetching(true);
    fetchData(id as string);
  }, [id]);

  useEventListener(EVENTS.REFRESH_DATA, refreshData);

  const handleRemoveCar = () => {
    // @ts-ignore
    setOrder((prev) => ({
      ...prev,
      car: null,
    }));
  };

  const handleRemoveAgent = () => {
    // @ts-ignore
    setOrder((prev) => ({
      ...prev,
      client: null,
    }));
  };

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  return (
    <>
      <div className={styles.wrapper}>
        <Meta title="Создание сделки" />

        <div className={styles.wrapperTop}>
          <Flex alignItems="center" justifyContent="space-between">
            <OrderTitle order={order} />
            <div>
              <p className={styles.wrapperSubdivision}>
                {currentSubdivision?.title}
              </p>
            </div>
          </Flex>
          <Grid gap="md" className={styles.grid}>
            <Grid.Item col={12}>
              <Grid>
                <OrderIndividualAgentForm
                  client={order?.client}
                  order={order}
                  onRemove={handleRemoveAgent}
                />
                <Grid.Item col={3}>
                  <OrderCarForm order={order} onRemoveCar={handleRemoveCar} />
                </Grid.Item>
                <Grid.Item col={3}>
                  <OrderCalendarForm order={order} />
                </Grid.Item>
              </Grid>
            </Grid.Item>
          </Grid>
        </div>
        <Scrollbar>
          <div className={styles.wrapperContent}>
            <OrderStatuses currentStatus={order?.status as OrderStatusEnum} />
            <Tabs
              className={styles.wrapperTabs}
              value={orderTab}
              onSelect={setOrderTab}
            >
              {orderTabs.map(({ label, value }) => (
                <Tabs.Item key={value} value={value}>
                  {label}
                </Tabs.Item>
              ))}
            </Tabs>
            {orderTab === OrderTab.Job && (
              <>
                <OrderJobs order={order} />
                <OrderProducts order={order} />
              </>
            )}
            {orderTab === OrderTab.Notes && (
              <OrderNotes notes={order?.notes as OrderNoteEntity[]} />
            )}
          </div>
        </Scrollbar>
        <Flex className={styles.total}>
          <Flex
            className={styles.totalItem}
            flexDirection="column"
            rowGap="xxs"
          >
            <p>{order?.jobs?.length} работ</p>
            <span>{order?.jobsPriceWithDiscount || 0} ₽</span>
          </Flex>
          <Flex
            className={styles.totalItem}
            rowGap="xxs"
            flexDirection="column"
          >
            <p>{order?.products?.length} товаров</p>
            <span>{order?.productsPriceWithDiscount || 0} ₽</span>
          </Flex>
          <Flex
            className={styles.totalItem}
            rowGap="xxs"
            flexDirection="column"
          >
            <p>Скидка</p>
            <span>
              {Number(order?.totalPrice) -
                Number(order?.totalPriceWithDiscount) || 0}{" "}
              ₽
            </span>
          </Flex>
          <Flex
            className={styles.totalItem}
            rowGap="xxs"
            flexDirection="column"
          >
            <p>Итого:</p>
            <span>{order?.totalPrice || 0} ₽</span>
          </Flex>
          <Flex
            className={styles.totalItem}
            rowGap="xxs"
            flexDirection="column"
          >
            <p>Сумма оплат:</p>
            <span>{order?.totalPriceWithDiscount || 0} ₽</span>
          </Flex>
          <If condition={Boolean(order?.payer)}>
            <Flex
              className={styles.totalItem}
              rowGap="xxs"
              flexDirection="column"
            >
              <p>Плательщик:</p>
              <span>
                {getAgentFullName(order?.payer as unknown as UserType)}
              </span>
            </Flex>
          </If>
          <Flex justifyContent="flex-end" className={styles.totalItem}>
            <Button
              onClick={() => setShowPaymentFormModal(true)}
              className={styles.wrapperSubmitBtn}
              variant="default"
            >
              Оплата
            </Button>
            <IconButton
              icon={IconPrint}
              label="Распечатать сделку"
              onClick={() => null}
              variant="secondary"
            />
          </Flex>
        </Flex>
      </div>

      <OrderPaymentFormModal
        open={showPaymentFormModal}
        onClose={() => setShowPaymentFormModal(false)}
        order={order as OrderEntity}
      />
    </>
  );
};

export default OrderForm;
