import { CalendarView } from "@/features/Calendar";
import Meta from "@/components/Meta";

const Calendar = () => {
  return (
    <>
      <Meta title="Календарь" />
      <CalendarView />
    </>
  );
};

export default Calendar;
