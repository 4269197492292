import React from "react";
import ReactDatePicker from "react-datepicker";
import dayjs from "@/utils/dayjs";
import cn from "classnames";
import IconArrowLeft from "@/components/Icons/IconArrowLeft";
import IconArrowRight from "@/components/Icons/IconArrowRight";
import InputDate from "@/components/UI/InputDate";
import { DatepickerProps } from "./types";
import IconCalendar from "@/components/Icons/IconCalendar";
import styles from "./styles.module.scss";
import IconButton from "@/components/UI/IconButton";
import { InputDateProps } from "@/components/UI/InputDate/types";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker = ({
  name,
  invalid,
  errorMessageId,
  descriptionId,
  date,
  error,
  value,
  dateFormat = "dd.MM.yyyy",
  placeholder = "дд.мм.гггг",
  popperPlacement = "bottom-start",
  inputClassName = "",
  label,
  ...props
}: DatepickerProps) => {
  const customInput = React.createElement(InputDate, {
    invalid,
    errorMessageId,
    descriptionId,
    className: inputClassName,
    format: props.showTimeSelect ? "#:##" : "##.##.####",
  } as InputDateProps);

  return (
    <div>
      {Boolean(label) && <div className={styles.label}>{label}</div>}
      <div
        className={cn(styles.wrapper, Boolean(error) && styles.wrapperInvalid)}
      >
        <ReactDatePicker
          {...props}
          customInput={customInput}
          calendarContainer={({ children, className }) => (
            <div className={className}>{children}</div>
          )}
          locale="ru"
          renderCustomHeader={(props) => (
            <div className={styles.header}>
              <IconButton
                icon={IconArrowLeft}
                className={styles.headerButton}
                disabled={props.prevMonthButtonDisabled}
                large
                type="button"
                onClick={props.decreaseMonth}
                label="Предыдущий месяц"
              />
              <span className={styles.headerTitle}>
                {dayjs(props.date).format("MMMM YYYY")}
              </span>
              <IconButton
                icon={IconArrowRight}
                className={styles.headerButton}
                disabled={props.nextMonthButtonDisabled}
                large
                type="button"
                onClick={props.increaseMonth}
                label="Следующий месяц"
              />
            </div>
          )}
          placeholderText={placeholder}
          dayClassName={() => styles.day}
          calendarStartDay={1}
          calendarClassName={styles.container}
          className={styles.datepicker}
          autoComplete="off"
          selected={date}
          dateFormat={dateFormat}
          popperPlacement={popperPlacement}
        />
        <IconCalendar />
      </div>
      {Boolean(error) && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default DatePicker;
