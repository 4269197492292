import Container from "@/components/Container";
import { Scrollbar } from "react-scrollbars-custom";
import Grid from "@/components/UI/Grid";
import Select from "@/components/UI/Select";
import { Form, Formik } from "formik";
import { FormFields } from "./constants";
import DatePicker from "@/components/UI/DatePicker";
import { useAppProvider } from "@/providers/AppProvider/hooks";
import {
  createWarehouseArrival,
  getWarehouseArrivalById,
  getWarehouses,
  updateWarehouseArrival,
} from "@/features/Warehouse/services";
import { useEffect, useState } from "react";
import LoadingIndicatorPage from "@/components/LoadingIndicatorPage";
import {
  WarehouseArrivalDocumentTypeEnum,
  WarehouseArrivalEntity,
  WarehouseEntity,
} from "@/features/Warehouse/types";
import {
  initialValues,
  warehouseArrivalDocuments,
} from "@/features/Warehouse/components/WarehouseArrivalForm/constants";
import Button from "@/components/UI/Button";
import Flex from "@/components/UI/Flex";
import { warehouseArrivalFormSchema } from "./schema";
import { useNavigate, useParams } from "react-router";
import { EVENTS, PATHS } from "@/constants";
import { triggerEvent } from "@/utils/event";
import { toast } from "react-toastify";
import { fetchSuppliers } from "@/pages/AgentDetail/components/AgentCallsListTable/service";
import {
  CounterpartAccount,
  CounterpartRoleEnum,
  CounterpartTypeEnum,
  IndividualAgent,
} from "@/types";
import { getAgentFullName } from "@/pages/AgentDetail/utils";
import { getAgentsByRole } from "@/features/Agent/utils";
import { getIndividualAgents } from "@/pages/Agents/components/AgentsListTable/service";
import { isAxiosError } from "axios";
import IconAdd from "@/components/Icons/IconAdd";
import { ProductForm } from "@/features/Product";
import Table from "@/components/UI/Table";
import { If } from "@/components/ConditionalRendering/If";
import { isArray, isEmpty, isString } from "lodash";
import { ProductEntity } from "@/features/Product/types";
import { AutoComplete } from "rsuite";
import { fetchSearchProducts } from "@/features/Product/services";
import WarehouseProductStockModal from "@/features/Warehouse/components/WarehouseProductStockModal";
import InputNumber from "@/components/UI/InputNumber";
import WarehouseProductModal from "@/features/Warehouse/components/WarehouseProductModal";
import styles from "./styles.module.scss";

const WarehouseArrivalForm = () => {
  const { id } = useParams();
  const { subdivisions } = useAppProvider();
  const navigate = useNavigate();
  const [warehouseArrival, setWarehouseArrival] =
    // @ts-ignore
    useState<WarehouseArrivalEntity>(initialValues);
  const [searchedProduct, setSearchedProduct] = useState<ProductEntity>();
  const [showProductModal, setShowProductModal] = useState(false);
  const [showProductFormModal, setShowProductFormModal] = useState(false);
  const [showProductStockFormModal, setShowProductStockFormModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [individualAgents, setIndividualAgents] = useState<IndividualAgent[]>(
    [],
  );
  const [legalAgents, setLegalAgents] = useState<CounterpartAccount[]>([]);
  const [products, setProducts] = useState<ProductEntity[]>([]);
  const [searchProducts, setSearchProducts] = useState<ProductEntity[]>([]);
  const [warehouses, setWarehouses] = useState<WarehouseEntity[]>([]);

  const isEdit = Boolean(id);

  useEffect(() => {
    const promises = [
      getIndividualAgents({
        pageNumber: 1,
        pageSize: 100,
      }),
      fetchSuppliers({
        pageNumber: 1,
        pageSize: 100,
      }),
      getWarehouses(),
    ];

    if (isEdit) {
      promises.push(getWarehouseArrivalById(id as string));
    }

    Promise.all(promises)
      .then(([individual, legal, wh, arrival]) => {
        setIndividualAgents(
          // @ts-ignore
          getAgentsByRole(individual?.items, CounterpartRoleEnum.Employee),
        );
        setLegalAgents(
          // @ts-ignore
          legal?.items.map((item) => ({
            ...item,
            counterpartProfile:
              item.counterpartType === CounterpartTypeEnum.Legal
                ? {
                    ...item?.counterpartProfile,
                    firstName: item?.counterpartProfile?.title,
                  }
                : item?.counterpartProfile,
          })),
        );
        setWarehouses(wh?.items);

        if (isEdit) {
          setProducts(
            // @ts-ignore
            arrival?.products.map((item) => ({
              ...item?.product,
              buyingPrice: item?.buyingPrice || 0,
            })),
          );
          setWarehouseArrival({
            ...arrival,
            responsible: arrival?.responsible?.counterpartProfile?._id,
            supplier: arrival?.supplier?._id,
            warehouse: arrival?.warehouse?.section?._id,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isEdit]);

  const handleUpdateProductAmount = (index: number, value: number) => {
    setProducts((prev) =>
      prev.map((item, i) => {
        if (index === i) {
          return {
            ...item,
            amount: value,
          };
        }
        return item;
      }),
    );
  };

  const handleUpdateProductBuyingPrice = (index: number, value: number) => {
    setProducts((prev) =>
      prev.map((item, i) => {
        if (index === i) {
          return {
            ...item,
            buyingPrice: value,
          };
        }
        return item;
      }),
    );
  };

  const goBack = () => {
    navigate(PATHS.ARRIVAL);
  };

  const handleSearchProducts = async (query: string) => {
    if (!isString(query)) return;

    try {
      const data = await fetchSearchProducts(query);

      setSearchProducts(
        data?.map((item: ProductEntity) => ({
          value: item,
          label: item.title,
        })),
      );
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data);
      }
    }
  };

  const handleUpdateProducts = async (
    product: ProductEntity | ProductEntity[],
  ) => {
    if (!isArray(product)) {
      product = [product];
    }
    setProducts((prev) => prev.concat(product));
  };

  const handleOnSubmit = async (values: WarehouseArrivalEntity) => {
    setIsFetching(true);

    const { accountingDate, documentType, supplier, responsible, warehouse } =
      values;

    try {
      const data = {
        accountingDate,
        documentType,
        products: products.map((product) => ({
          product: product._id,
          amount: product.amount,
          buyingPrice: product.buyingPrice,
        })),
        responsible,
        supplier,
        warehouse,
      };

      if (isEdit) {
        await updateWarehouseArrival(id as string, data);
      } else {
        await createWarehouseArrival(data);
      }

      triggerEvent(EVENTS.REFRESH_DATA);
      toast.success("Поступление добавлено");
      navigate(PATHS.ARRIVAL);
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const handleAddProduct = async (product: ProductEntity) => {
    setSearchedProduct(product);
    handleUpdateProducts(product);
  };

  if (isLoading) {
    return <LoadingIndicatorPage />;
  }

  return (
    <div className={styles.container}>
      <Formik
        enableReinitialize
        validateOnChange={false}
        // @ts-ignore
        initialValues={warehouseArrival}
        onSubmit={handleOnSubmit}
        validationSchema={warehouseArrivalFormSchema}
      >
        {({ values, setFieldValue, handleChange, errors }) => (
          <Form className={styles.form}>
            <Scrollbar>
              <Container center>
                <h2 className={styles.title}>
                  {isEdit ? "Редактирование поступления" : "Новое поступление"}
                </h2>
                <Flex flexDirection="column" rowGap="xl">
                  <Grid gap="sm">
                    <Grid.Item col={6}>
                      <Select
                        error={errors[FormFields.DocumentType]}
                        name={FormFields.DocumentType}
                        label="Тип документа"
                        defaultValue={{
                          label:
                            warehouseArrivalDocuments[
                              values[
                                FormFields.DocumentType
                              ] as WarehouseArrivalDocumentTypeEnum
                            ],
                          value: values[FormFields.DocumentType],
                        }}
                        options={Object.entries(warehouseArrivalDocuments).map(
                          ([value, label]) => ({
                            label,
                            value,
                          }),
                        )}
                        onChange={(e) => {
                          setFieldValue(FormFields.DocumentType, e!.value);
                        }}
                      />
                    </Grid.Item>
                    <Grid.Item col={6}>
                      <Select
                        error={errors[FormFields.Supplier]}
                        name={FormFields.Supplier}
                        defaultValue={{
                          label: getAgentFullName(
                            legalAgents.find(
                              (item) =>
                                item._id === values[FormFields.Supplier],
                            )?.counterpartProfile,
                          ),
                          value: values[FormFields.Supplier],
                        }}
                        label="Поставщик"
                        options={legalAgents.map((legalAgent) => ({
                          label: getAgentFullName(
                            legalAgent?.counterpartProfile,
                          ),
                          value: legalAgent._id,
                        }))}
                        onChange={(e) => {
                          setFieldValue(FormFields.Supplier, e!.value);
                        }}
                      />
                    </Grid.Item>
                    <Grid.Item col={6}>
                      <Select
                        label="Склад"
                        error={errors[FormFields.Warehouse]}
                        name={FormFields.Warehouse}
                        defaultValue={{
                          label: warehouses.find(
                            (item) =>
                              item.section?._id ===
                              values[FormFields.Warehouse],
                          )?.title,
                          value: values[FormFields.Warehouse],
                        }}
                        // @ts-ignore
                        options={warehouses?.map((warehouse) => ({
                          label: warehouse.title,
                          value: warehouse._id,
                        }))}
                        onChange={(e) => {
                          setFieldValue(FormFields.Warehouse, e!.value);
                        }}
                      />
                    </Grid.Item>
                    <Grid.Item col={6}>
                      <Select
                        error={errors[FormFields.Responsible]}
                        name={FormFields.Responsible}
                        label="Ответственный менеджер"
                        defaultValue={{
                          label: getAgentFullName(
                            // @ts-ignore
                            individualAgents.find(
                              (item) =>
                                item._id === values[FormFields.Responsible],
                            ),
                          ),
                          value: values[FormFields.Responsible],
                        }}
                        options={individualAgents.map((individualAgent) => ({
                          label: getAgentFullName(individualAgent),
                          value: individualAgent?.account?._id,
                        }))}
                        onChange={(e) => {
                          setFieldValue(FormFields.Responsible, e!.value);
                        }}
                      />
                    </Grid.Item>
                    <Grid.Item col={12}>
                      <DatePicker
                        // @ts-ignore
                        date={values[FormFields.AccountingDate]}
                        error={errors[FormFields.AccountingDate]}
                        name={FormFields.AccountingDate}
                        maxDate={new Date()}
                        label="Дата учета"
                        placeholder="Выберите дату учета"
                        onChange={(value: Date) => {
                          setFieldValue(
                            FormFields.AccountingDate,
                            new Date(value),
                          );
                        }}
                      />
                    </Grid.Item>
                  </Grid>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center">
                      <span>Товары</span>
                      <div className={styles.formSearch}>
                        <AutoComplete
                          data={searchProducts}
                          onChange={handleSearchProducts}
                          onSelect={handleAddProduct}
                          placeholder="Поиск товаров для добавления в таблицу"
                          className={styles.autocomplete}
                          value={searchedProduct?.title}
                        />
                      </div>
                      <Button
                        endIcon={IconAdd}
                        onClick={() => setShowProductModal(true)}
                        variant="secondary"
                      >
                        Добавление товара
                      </Button>
                    </Flex>
                    <Flex>
                      <Button
                        onClick={() => setShowProductStockFormModal(true)}
                        variant="empty-primary"
                      >
                        Из остатков
                      </Button>
                      <Button
                        onClick={() => setShowProductFormModal(true)}
                        variant="empty-primary"
                      >
                        Из номенклатуры
                      </Button>
                    </Flex>
                  </Flex>
                  <If condition={!isEmpty(products)}>
                    <Table
                      className={styles.table}
                      header={
                        <Table.Row>
                          <Table.Cell>Название</Table.Cell>
                          <Table.Cell>Бренд</Table.Cell>
                          <Table.Cell>Артикул</Table.Cell>
                          <Table.Cell>Количество</Table.Cell>
                          <Table.Cell>Текущая цена поставки</Table.Cell>
                          <Table.Cell>Итоговая сумма</Table.Cell>
                        </Table.Row>
                      }
                    >
                      {products?.map((product, index) => (
                        <Table.Row className={styles.tableRow} key={index}>
                          <Table.Cell>{product.title}</Table.Cell>
                          <Table.Cell>{product?.brand}</Table.Cell>
                          <Table.Cell>{product?.article}</Table.Cell>
                          <Table.Cell>
                            <InputNumber
                              onChange={(e) => {
                                handleUpdateProductAmount(
                                  index,
                                  Number(e.currentTarget.value),
                                );
                              }}
                              placeholder="Введите количество"
                              variant="small"
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <InputNumber
                              onChange={(e) => {
                                handleUpdateProductBuyingPrice(
                                  index,
                                  Number(e.currentTarget.value),
                                );
                              }}
                              placeholder="Введите текущую цену поставки"
                              variant="small"
                            />
                          </Table.Cell>
                          <Table.Cell>
                            {(product?.amount || 1) *
                              (product?.buyingPrice || 0)}{" "}
                            ₽
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table>
                  </If>
                </Flex>
              </Container>
            </Scrollbar>
            <Flex
              className={styles.formActions}
              alignItems="center"
              columnGap="sm"
            >
              <Button
                disabled={isFetching}
                isLoading={isFetching}
                type="submit"
              >
                {isEdit ? "Сохранить поставку" : "Принять поставку"}
              </Button>
              <Button
                disabled={isFetching}
                onClick={goBack}
                variant="secondary"
              >
                Закрыть
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>

      <ProductForm
        isOpen={showProductModal}
        onClose={() => setShowProductModal(false)}
        onSuccess={handleUpdateProducts}
        warehouseArrival={warehouseArrival}
      />

      <If condition={showProductStockFormModal}>
        <WarehouseProductStockModal
          isOpen={true}
          onSuccess={handleUpdateProducts}
          onClose={() => setShowProductStockFormModal(false)}
        />
      </If>

      <If condition={showProductFormModal}>
        <WarehouseProductModal
          isOpen={true}
          onSuccess={handleUpdateProducts}
          onClose={() => setShowProductFormModal(false)}
        />
      </If>
    </div>
  );
};

export default WarehouseArrivalForm;
