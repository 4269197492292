import Modal from "@/components/UI/Modal";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import ModalBody from "@/components/UI/Modal/ModalBody";
import { useOnce } from "@/hooks";
import { useCallback, useEffect, useState } from "react";
import IconLoading from "@/components/Icons/IconLoading";
import Table from "@/components/UI/Table";
import {
  OrderProductEntity,
  ProductEntity,
  ProductStockEntity,
  ProductStockGroupEntity,
} from "@/features/Product/types";
import { isEmpty, map } from "lodash";
import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import {
  getWarehouseProductStockGrouped,
  getWarehouseProductStockGroupedById,
} from "@/features/Warehouse/services";
import { ProductOrderItemProps, ProductOrderModalProps } from "./types";
import Checkbox from "@/components/UI/Checkbox";
import { updateOrder } from "@/features/Order/services";
import { triggerEvent } from "@/utils/event";
import { EVENTS } from "@/constants";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import { If } from "@/components/ConditionalRendering/If";
import IconArrowSubDown from "@/components/Icons/IconArrowSubDown";
import IconArrowUp from "@/components/Icons/IconArrowUp";
import IconArrowDown from "@/components/Icons/IconArrowDown";
import styles from "./styles.module.scss";

const ProductOrderModal = ({
  id,
  isOpen,
  onClose,
  order,
}: ProductOrderModalProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [products, setProducts] = useState<
    ProductStockGroupEntity[] | ProductStockEntity[]
  >([]);
  const [selectedProducts, setSelectedProducts] = useState<
    ProductStockEntity[]
  >([]);

  useEffect(() => {
    const handler = id
      ? getWarehouseProductStockGroupedById(id as string, {
          pageSize: 100,
          pageNumber: 1,
        })
      : getWarehouseProductStockGrouped({
          pageSize: 100,
          pageNumber: 1,
        });

    handler
      .then((res) => {
        setProducts(res?.items || res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const handleSubmit = async () => {
    setIsFetching(true);

    try {
      await updateOrder(order?._id as string, {
        products: order?.products
          ?.map((product) => ({
            stock: product.stock?._id,
          }))
          .concat(
            // @ts-ignore
            selectedProducts.map((product) => ({
              stock: product._id,
            })),
          ),
      });
      toast.success("Товары успешно добавлены");
      triggerEvent(EVENTS.REFRESH_DATA);
      onClose?.();
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data?.message);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const handleToggleProduct = useCallback((product: ProductStockEntity) => {
    const hasProduct = selectedProducts.find(
      (productItem) => productItem._id === product._id,
    );

    if (hasProduct) {
      setSelectedProducts((prev) =>
        prev.filter((productItem) => productItem._id !== product._id),
      );
    } else {
      setSelectedProducts((prev) => prev.concat(product));
    }
  }, []);

  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <ModalHeader onClose={onClose}>
        <h2>Склад</h2>
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <IconLoading />
        ) : (
          <Table
            header={
              <Table.Row>
                <Table.Cell>Наименование</Table.Cell>
                <Table.Cell>Количество</Table.Cell>
                <Table.Cell>Объем / мин.объем</Table.Cell>
                <Table.Cell>В резерве</Table.Cell>
                <Table.Cell>Доступно</Table.Cell>
                <Table.Cell>Цена закупки</Table.Cell>
                <Table.Cell>Склад</Table.Cell>
                <Table.Cell>Адрес</Table.Cell>
              </Table.Row>
            }
            isEmpty={!products.length}
            isFetching={isLoading}
          >
            {map(products, (product, index) => (
              <ProductOrderItem
                key={index}
                expanded={Boolean(id)}
                onSelect={handleToggleProduct}
                product={product as ProductStockGroupEntity}
              />
            ))}
          </Table>
        )}
      </ModalBody>
      <ModalFooter>
        <Flex alignItems="center">
          <Button
            disabled={
              isEmpty(products) || isEmpty(selectedProducts) || isFetching
            }
            isLoading={isFetching}
            onClick={handleSubmit}
          >
            Добавить в сделку
          </Button>
          <Button onClick={onClose} variant="secondary">
            Отмена
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export const ProductOrderItem = ({
  expanded = false,
  onSelect,
  product,
}: ProductOrderItemProps) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  const handleToggle = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  return (
    <>
      <Table.Row className={styles.row} onClick={handleToggle}>
        <Table.Cell>
          <Flex alignItems="center">{product?.groupProduct?.title}</Flex>
        </Table.Cell>
        <Table.Cell>{product?.groupTotal} шт.</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>{product?.groupReserved}</Table.Cell>
        <Table.Cell>{product?.groupAvailable} шт.</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>{product?.groupWarehouse?.title}</Table.Cell>
        <Table.Cell>
          <Flex>
            <span>{product?.groupWarehouse?.address}</span>
            {isExpanded ? <IconArrowDown /> : <IconArrowUp />}
          </Flex>
        </Table.Cell>
      </Table.Row>
      <If condition={isExpanded}>
        {map(product?.groupStock, (stock) => (
          <Table.Row key={stock?._id}>
            <Table.Cell>
              <Flex alignItems="center" columnGap="xs" className={styles.cell}>
                <IconArrowSubDown />
                <Checkbox onClick={() => onSelect(stock)} />
                <span>{stock?.product?.title}</span>
              </Flex>
            </Table.Cell>
            <Table.Cell>1 шт.</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>{stock?.reserved}</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>{stock?.buyingPrice} ₽</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        ))}
      </If>
    </>
  );
};

export default ProductOrderModal;
