import cn from "classnames";
import type { TableCellProps } from "./types";
import type { PropsWithChildren } from "react";
import styles from "./styles.module.scss";
import { isNumber } from "lodash";

const TableCell = ({
  index,
  children,
  className,
  colSpan,
  width,
}: PropsWithChildren<TableCellProps>) => {
  const style: Record<string, unknown> = {
    maxWidth: width || "auto",
  };

  if (isNumber(index)) {
    style.zIndex = 2 + index;
  }

  return (
    <td className={cn(styles.cell, className)} colSpan={colSpan} style={style}>
      {children}
    </td>
  );
};

export default TableCell;
