import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";

dayjs.locale(ru);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);

export default dayjs;
