import dayjs from "@/utils/dayjs";
import { Dayjs } from "dayjs";

export enum CalendarRange {
  Day = "day",
  Month = "month",
  Week = "week",
}

export const calendarRanges = [
  { label: CalendarRange.Day, value: "День" },
  { label: CalendarRange.Month, value: "Месяц" },
  { label: CalendarRange.Week, value: "Неделя" },
];

export const getRangeValues = (
  range: CalendarRange,
): { label: string; values: Dayjs[] } => {
  switch (range) {
    case CalendarRange.Month: {
      const startDate = dayjs()
        .set("hour", 9)
        .set("minute", 0)
        .set("second", 0);

      return {
        label: "Дата",
        values: [
          startDate,
          startDate.add(1, "hour"),
          startDate.add(2, "hour"),
          startDate.add(3, "hour"),
          startDate.add(4, "hour"),
          startDate.add(5, "hour"),
          startDate.add(6, "hour"),
          startDate.add(7, "hour"),
          startDate.add(8, "hour"),
          startDate.add(9, "hour"),
        ],
      };
    }

    case CalendarRange.Week: {
      const startDate = dayjs()
        .set("hour", 9)
        .set("minute", 0)
        .set("second", 0);

      return {
        label: "Дата",
        values: [
          startDate,
          startDate.add(1, "hour"),
          startDate.add(2, "hour"),
          startDate.add(3, "hour"),
          startDate.add(4, "hour"),
          startDate.add(5, "hour"),
          startDate.add(6, "hour"),
          startDate.add(7, "hour"),
          startDate.add(8, "hour"),
          startDate.add(9, "hour"),
        ],
      };
    }

    case CalendarRange.Day: {
      const startDate = dayjs()
        .set("hour", 9)
        .set("minute", 0)
        .set("second", 0);

      return {
        label: "Время",
        values: [
          startDate,
          startDate.add(1, "hour"),
          startDate.add(2, "hour"),
          startDate.add(3, "hour"),
          startDate.add(4, "hour"),
          startDate.add(5, "hour"),
          startDate.add(6, "hour"),
          startDate.add(7, "hour"),
          startDate.add(8, "hour"),
          startDate.add(9, "hour"),
        ],
      };
    }
  }
};
