import cn from "classnames";
import { capitalize } from "lodash";
import Flex from "@/components/UI/Flex";
import IconCalendar from "@/components/Icons/IconCalendar";
import dayjs from "@/utils/dayjs";
import type { CalendarEntryItemProps } from "./types";
import styles from "./styles.module.scss";

export const CalendarEntryItem = ({ index, item }: CalendarEntryItemProps) => {
  return (
    <div
      className={cn(
        styles.calendarEntry,
        // @ts-ignore
        styles[`calendarEntry${capitalize(item.marker)}`],
      )}
      style={{
        zIndex: 111 + index,
      }}
    >
      <Flex alignItems="center" columnGap="xxs">
        <IconCalendar />
        <span>({dayjs(item.startAt).format("DD:MM")})</span>
        <span>{dayjs(item.startAt).format("HH:mm")}</span>
        <span>-</span>
        <span>{dayjs(item.endAt).format("HH:mm")}</span>
        <span>({dayjs(item.endAt).format("DD:MM")})</span>
      </Flex>
    </div>
  );
};
