import cn from "classnames";
import { useMemo, useState } from "react";
import { CalendarRange, calendarRanges } from "../CalendarRangeCells/constants";
import Flex from "@/components/UI/Flex";
import IconButton from "@/components/UI/IconButton";
import IconArrowLeft from "@/components/Icons/IconArrowLeft";
import IconArrowRight from "@/components/Icons/IconArrowRight";
import IconCalendar from "@/components/Icons/IconCalendar";
import dayjs from "@/utils/dayjs";
import type { Dayjs } from "dayjs";
import type { CalendarRangesProps } from "./types";
import styles from "./styles.module.scss";

export const CalendarRanges = ({ range, onSelect }: CalendarRangesProps) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

  const handleSelectDate = (operation: "add" | "subtract") => {
    setSelectedDate((prev) => prev[operation](1, "d"));
  };

  const renderDatePicker = useMemo(() => {
    if (range === CalendarRange.Day) {
      return (
        <>
          <IconButton
            icon={IconArrowLeft}
            onClick={() => handleSelectDate("subtract")}
          />
          <Flex alignItems="center" className={styles.pickerInner}>
            <IconCalendar />
            {dayjs(selectedDate).format("DD MMMM YYYY")}
          </Flex>
          <IconButton
            icon={IconArrowRight}
            onClick={() => handleSelectDate("add")}
          />
        </>
      );
    } else if (range === CalendarRange.Month) {
      return <></>;
    } else if (range === CalendarRange.Week) {
      return <></>;
    }
  }, [calendarRanges, range, selectedDate]);

  const renderRanges = useMemo(
    () =>
      calendarRanges.map((calendarRange) => (
        <li key={calendarRange.label}>
          <button
            className={cn(
              styles.rangesButton,
              range === calendarRange.label && styles.rangesButtonActive,
            )}
            onClick={() => onSelect(calendarRange.label)}
          >
            {calendarRange.value}
          </button>
        </li>
      )),
    [calendarRanges, range],
  );

  return (
    <Flex alignItems="center">
      <Flex
        className={styles.picker}
        columnGap="zero"
        justifyContent="space-between"
      >
        {renderDatePicker}
      </Flex>
      <ul className={styles.ranges}>{renderRanges}</ul>
    </Flex>
  );
};
