import { useState } from "react";
import Header from "@/components/Header";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import LoadingIndicatorPage from "@/components/LoadingIndicatorPage";
import { CalendarRange, getRangeValues } from "../CalendarRangeCells/constants";
import { If } from "@/components/ConditionalRendering/If";
import { CalendarFormModal, CalendarPostModal } from "@/features/Calendar";
import { CalendarRanges } from "../CalendarRanges";
import { useCalendarEntryQuery } from "@/features/Calendar/hooks/useCalendarEntryQuery";
import { useEventListener } from "@/hooks";
import { EVENTS } from "@/constants";
import { CalendarTable } from "@/features/Calendar/components/CalendarTable";
import dayjs from "@/utils/dayjs";

export const CalendarView = () => {
  const [range, setRange] = useState<CalendarRange>(CalendarRange.Day);
  const [showCalendarEntityModal, setShowCalendarEntityModal] = useState(false);
  const [showPostModal, setShowPostModal] = useState(false);

  const {
    isFetching,
    data: calendarGroup,
    refetch,
  } = useCalendarEntryQuery({
    from: dayjs().format("YYYY-MM-DD"),
    to: dayjs().add(1, "day").format("YYYY-MM-DD"),
  });

  useEventListener(EVENTS.REFRESH_DATA, () => refetch());

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  const ranges = getRangeValues(range);

  // @ts-ignore
  const res = calendarGroup.map((calendarItem) => {
    const entries = ranges.values.reduce((all, range) => {
      const entry = calendarItem.entries.filter(
        // @ts-ignore
        (entryItem) => {
          return dayjs(entryItem.startAt).isBetween(
            range,
            range.add(1, "hour"),
            "hour",
            "[)",
          );
        },
      );

      if (entry) {
        // @ts-ignore
        all.push(entry);
      }

      //return calendarItem.entries.map((entry) => entry);

      return all;
    }, []);

    return {
      ...calendarItem,
      entries,
    };
  });

  return (
    <>
      <Header
        actions={
          <>
            <Button
              endIcon={IconAdd}
              onClick={() => setShowCalendarEntityModal(true)}
            >
              Запись
            </Button>
            <Button
              endIcon={IconAdd}
              onClick={() => setShowPostModal(true)}
              variant="secondary"
            >
              Пост
            </Button>
          </>
        }
        showSearch={false}
      >
        <CalendarRanges onSelect={setRange} range={range} />
      </Header>

      <CalendarTable data={res} range={range} />

      <If condition={showCalendarEntityModal}>
        <CalendarFormModal
          isOpen={showCalendarEntityModal}
          onClose={() => setShowCalendarEntityModal(false)}
          calendarGroup={calendarGroup}
        />
      </If>

      <If condition={showPostModal}>
        <CalendarPostModal
          isOpen={showPostModal}
          onClose={() => setShowPostModal(false)}
        />
      </If>
    </>
  );
};
