import Modal from "@/components/UI/Modal";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import ModalBody from "@/components/UI/Modal/ModalBody";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import { OrderCatalogJobModalProps } from "./types";
import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { JobCategoryEntity, JobEntity } from "@/features/Job/types";
import { updateOrder } from "@/features/Order/services";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { triggerEvent } from "@/utils/event";
import { EVENTS } from "@/constants";
import { CategoryType } from "@/features/Job/components/JobCategories/constants";
import Table from "@/components/UI/Table";
import { isEmpty, isFunction } from "lodash";
import Checkbox from "@/components/UI/Checkbox";
import { Scrollbar } from "react-scrollbars-custom";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import {
  getJobCategories,
  getJobs,
  getJobsByCategory,
} from "@/features/Job/services";
import { useOnce } from "@/hooks";

const OrderCatalogJobModal = ({
  open,
  onClose,
  order,
}: OrderCatalogJobModalProps) => {
  const [orderJobs, setOrderJobs] = useState<JobEntity[]>([]);
  const [jobs, setJobs] = useState<Set<string>>(new Set());
  const [isFetching, setIsFetching] = useState(false);
  const [category, setCategory] = useState<string | null>();
  const [categories, setCategories] = useState<JobCategoryEntity[]>([]);

  const handleAddJob = (job: JobEntity) => {
    if (jobs.has(job._id)) {
      jobs.delete(job._id);
    } else {
      jobs.add(job._id);
    }

    setJobs(() => new Set(jobs));
  };

  const fetchData = () => {
    getJobCategories()
      .then((data) => {
        setCategories(data.items);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useOnce(fetchData);

  useEffect(() => {
    if (category) {
      getJobsByCategory(category).then((data) => {
        setOrderJobs(data.items);
      });
    } else {
      getJobs({ pageNumber: 1, pageSize: 20 }).then((data) => {
        setOrderJobs(data.items);
      });
    }
  }, [category]);

  const handleUpdateJobOrders = async () => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      await updateOrder(order?._id as string, {
        jobs: order?.jobs
          ?.map((job) => ({ job: job?.job?._id }))
          .concat(Array.from(jobs).map((jobId) => ({ job: jobId }))),
      });
      triggerEvent(EVENTS.REFRESH_DATA);
      toast.success(
        jobs.size > 1
          ? "Работы добавлены в сделку"
          : "Работа добавлена в сделку",
      );
      onClose();
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const renderMenu = () => {
    return categories.map(({ title, _id }, index) => (
      <li className={styles.navItem} key={index}>
        <Button
          onClick={() => setCategory(_id)}
          className={styles.navButton}
          fullWidth
          variant="link"
        >
          {title}
        </Button>
      </li>
    ));
  };

  return (
    <Modal className={styles.modal} isOpen={open}>
      <ModalHeader onClose={onClose}>
        <h2>Каталог работ</h2>
      </ModalHeader>
      <ModalBody className={styles.jobs}>
        <Scrollbar
          style={{ height: 528 }}
          scrollerProps={{
            renderer: (props) => {
              const { elementRef, ...restProps } = props;
              return <span {...restProps} ref={elementRef} />;
            },
          }}
        >
          <ul className={styles.navList}>
            <li className={styles.navItem}>
              <Button
                onClick={() => setCategory(null)}
                className={styles.navButton}
                fullWidth
                variant="link"
              >
                Все работы
              </Button>
            </li>
            {renderMenu()}
          </ul>
        </Scrollbar>
        <Scrollbar style={{ height: 528 }}>
          <Table
            className={styles.table}
            header={
              <Table.Row>
                <Table.Cell />
                <Table.Cell>Наименование работы</Table.Cell>
                <Table.Cell>Норма времени</Table.Cell>
                <Table.Cell>Цена 1 НЧ</Table.Cell>
                <Table.Cell>Фиксированная ЗП</Table.Cell>
                <Table.Cell>Стоимость</Table.Cell>
              </Table.Row>
            }
            isEmpty={isEmpty(orderJobs)}
            isFetching={isFetching}
          >
            {orderJobs.map((job) => (
              <Table.Row key={job._id}>
                <Table.Cell>
                  <Checkbox
                    className={styles.checkbox}
                    onChange={() => {
                      handleAddJob(job);
                    }}
                  />
                </Table.Cell>
                <Table.Cell>{job.title}</Table.Cell>
                <Table.Cell>{job.manHours}</Table.Cell>
                <Table.Cell>{job.manHourPrice}</Table.Cell>
                <Table.Cell>{job.fixedPaymentAmount}</Table.Cell>
                <Table.Cell>{job.price}</Table.Cell>
              </Table.Row>
            ))}
          </Table>
        </Scrollbar>
      </ModalBody>
      <ModalFooter>
        <Flex>
          <Button
            disabled={!Boolean(jobs?.size) || isFetching}
            isLoading={isFetching}
            onClick={handleUpdateJobOrders}
          >
            Добавить в сделку
          </Button>
          <Button disabled={isFetching} onClick={onClose} variant="secondary">
            Отмена
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export default OrderCatalogJobModal;
