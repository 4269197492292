import { CalendarRangeCells } from "@/features/Calendar/components/CalendarRangeCells";
import { map } from "lodash";
import TableRow from "@/components/UI/Table/TableRow";
import TableCell from "@/components/UI/Table/TableCell";
import Flex from "@/components/UI/Flex";
import IconButton from "@/components/UI/IconButton";
import IconEdit from "@/components/Icons/IconEdit";
import Table from "@/components/UI/Table";
import styles from "./styles.module.scss";
import { CalendarPostModal } from "@/features/Calendar";
import { If } from "@/components/ConditionalRendering/If";
import { useState } from "react";
import { ServiceBay } from "@/features/Order/types";
import type { CalendarTableProps } from "./types";
import { CalendarEntryItem } from "@/features/Calendar/components/CalendarEntryItem";

export const CalendarTable = ({ data, range }: CalendarTableProps) => {
  const [post, setPost] = useState<ServiceBay | null>(null);
  const [showPostModal, setShowPostModal] = useState(false);

  const handleEditPost = (value: ServiceBay) => {
    setPost(value);
    setShowPostModal(true);
  };

  return (
    <>
      <Table className={styles.table}>
        <CalendarRangeCells range={range} />
        {map(data, (item, index) => (
          <TableRow key={item._id}>
            <TableCell>
              <Flex alignItems="center">
                <span>{item?.serviceBay?.title}</span>
                <IconButton
                  icon={IconEdit}
                  onClick={() => handleEditPost(item?.serviceBay)}
                />
              </Flex>
            </TableCell>
            {map(item?.entries, (entries, index) => (
              <TableCell index={index} key={index}>
                {map(entries, (entry, index) => (
                  <CalendarEntryItem index={index} key={index} item={entry} />
                ))}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
      <If condition={showPostModal}>
        <CalendarPostModal
          post={post}
          isOpen={showPostModal}
          onClose={() => setShowPostModal(false)}
        />
      </If>
    </>
  );
};
